<template>
  <div>
    <v-row>
      <v-col lg="6">
        <students-filter
          @getData="setDataFilter($event)"
          @clearData="clearData"
        ></students-filter>
      </v-col>
    </v-row>
    <data-table
      @updatePage="getData($event)"
      :headers="data.headers"
      :data="data.data"
      :meta="data.meta"
      :loader="loader"
      :showable="showAble"
    />
  </div>
</template>
<script>
import studentsFilter from "../../../Dashboard/schools/teacher&students/students/filter.vue";
export default {
  props: ["data", "loader"],
  components: {
    studentsFilter,
  },
  data() {
    return {
      filterData: {},
    };
  },
  methods: {
    getData(page) {
      Object.assign(this.filterData, { page: page });
      this.$emit("getData", this.filterData);
    },
    setDataFilter(data) {
      this.filterData = data;
      this.getData(1);
    },
    clearData() {
      this.filterData = {};
      this.getData({ page: 1, edu_class_id: this.id });
    },
    showAble(obj) {
      this.$router.push(`/student_exams/${obj.id}`);
    },
  },
};
</script>
