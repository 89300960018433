<template>
  <v-row>
    <v-col lg="4">
      <v-text-field
        v-model="data.first_name"
        :label="$t('serach-by-name')"
        outlined
      ></v-text-field>
    </v-col>

    <v-col lg="4">
      <v-text-field
        v-model="data.last_name"
        :label="$t('serach-by-last-name')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col lg="4">
      <v-icon
        @click="$emit('getData', data)"
        color="primary"
        class="ma-2"
        x-large
        >mdi-magnify</v-icon
      >
      <v-icon @click="clearData" color="primary" class="ma-2" x-large
        >mdi-close</v-icon
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    clearData() {
      this.data = {};
      this.$emit("clearData");
    },
    setFromDate() {
      this.modal = false;
    },
  },
};
</script>
