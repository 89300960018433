<template>
  <div>
    <data-table
      @updatePage="getData($event)"
      :headers="headers"
      :data="data.data"
      :meta="data.meta"
      :loader="loader"
      :showable="showAble"
    >
      <template #header:status>
        {{ $t("status") }}
      </template>
      <template #status="{item}">
        <div class="d-flex justify-center align-center">
          <v-switch
            v-if="item.status == 'active'"
            @change="changeStatus(item)"
            inset
            v-model="statusTrue"
          ></v-switch>
          <v-switch
            v-else
            @change="changeStatus(item)"
            inset
            v-model="statusFalse"
          ></v-switch>
        </div>
      </template>
    </data-table>
  </div>
</template>
<script>
import { Station } from "@/store/teacher/statoins";
export default {
  props: ["id"],
  data() {
    return {
      Station,
      headers: {
        id: {
          name: "id",
          keys: ["id"],
        },

        name: {
          name: "name",
          keys: ["name"],
        },
        type: {
          name: "type",
          keys: ["type"],
        },
        created_at: {
          name: "created_at",
          keys: ["created_at"],
        },
        status: {
          slot: "status",
        },
      },
      statusTrue: true,
      statusFalse: false,
    };
  },
  methods: {
    itemStatus(item) {
      return {
        get() {
          return item.status === "active";
        },
        set(value) {
          item.status = value ? "active" : "inactive";
        },
      };
    },
    getData() {
      Station.get({ edu_class_id: this.id });
    },
    async changeStatus(item) {
      let status = item.status == "active" ? "locked" : "active";
      await Station.edit({
        id: item.id,
        payload: {
          status: status,
          edu_class_id: this.id,
        },
        onSuccess: () => {
          item.status = status;
        },
      });
    },
    showAble(obj) {
      this.$router.push(`/station/${obj.id}`);
    },
  },
  computed: {
    data() {
      return Station.tableData;
    },
    loader() {
      return Station.getState.loading;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
