<template>
  <v-container v-if="!loader" class="d-block mt-n8">
    <v-row class="mt-n16 mb-8 justify-center">
      <v-col class="d-flex justify-center" lg="12">
        <h1 class="primary--text">{{ data.name }}</h1>
      </v-col>
    </v-row>
    <v-row class="justify-space-around align-center my-6 text-center">
      <v-col lg="3">
        <p>
          {{ $t("grade") }}
        </p>
        <h2 class="primary--text">{{ data.grade }}</h2>
      </v-col>
      <v-col lg="3">
        <p>{{ $t("educational_level") }}</p>
        <h2 class="primary--text">{{ data.educational_level }}</h2>
      </v-col>
      <v-col lg="3">
        <p>{{ $t("student_count") }}</p>
        <h2 class="primary--text">{{ data.student_count }}</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <template>
      <v-divider></v-divider>

      <v-tabs grow>
        <v-tab> {{ $t("students") }} </v-tab>
        <v-tab> {{ $t("exams") }} </v-tab>
        <v-tab> {{ $t("educational content") }} </v-tab>

        <v-tab-item class="my-4">
          <students
            @getData="getStudentsData($event)"
            :data="studentsData"
            :loader="studentsLoader"
          />
        </v-tab-item>
        <v-tab-item class="my-4">
          <exams
            @getData="getExamsData($event)"
            :data="examsData"
            :loader="examsLoader"
            :id="id"
          />
        </v-tab-item>
        <v-tab-item class="my-4">
          <station :id="id" />
        </v-tab-item>
      </v-tabs>
    </template>
  </v-container>
  <pulse-loader
    v-else
    :loading="true"
    :color="$vuetify.theme.themes.light.primary"
    size="20px"
  />
</template>
<script>
import { eduClass } from "@/store/teacher/eduClass/index";
import { Users as Student } from "@/store/teacher/students/index";
import { Exams } from "@/store/teacher/exams";
import { Exercise } from "@/store/teacher/exercise";
import students from "./students/index.vue";
import exams from "./exams/index.vue";
import station from "./station/index.vue";
// import exercise from "./exercise/index.vue";
export default {
  components: {
    students,
    exams,
    station,
  },
  data() {
    return {
      eduClass,
      Student,
      Exams,
      Exercise,
    };
  },
  methods: {
    getStudentsData(data) {
      Object.assign(data, { edu_class_id: this.id });
      Student.get(data);
    },
    getExamsData(data) {
      Object.assign(data, { edu_class_id: this.id });
      Exams.get(data);
    },
    getExerciseData(data) {
      Object.assign(data, { edu_class_id: this.id });
      Exercise.get(data);
    },
  },
  computed: {
    data() {
      return eduClass.tableData.showData;
    },
    studentsData() {
      return Student.tableData;
    },
    examsData() {
      return Exams.tableData;
    },
    exerciseData() {
      return Exercise.tableData;
    },
    loader() {
      return eduClass.showState.loading;
    },
    studentsLoader() {
      return Student.getState.loading;
    },
    examsLoader() {
      return Exams.getState.loading;
    },
    exerciseLoader() {
      return Exercise.getState.loading;
    },
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    eduClass.show(this.id);
    Student.get({ page: 1, edu_class_id: this.id });
    Exams.get({ page: 1, edu_class_id: this.id });
    Exercise.get({ page: 1, edu_class_id: this.id });
  },
};
</script>
<style lang="scss" scoped>
p {
  font-weight: 600;
}
</style>
